import { globalConfig } from "../constants";
import moment from "moment";

export const setNumberPrecision = (val) => {
  return val && val.toFixed(2);
};

export const setCartTotal = (cartObj) => {
  let totalOrderAmount = 0;
  Object.keys(cartObj).map((key) => (totalOrderAmount += cartObj[key].price));
  return totalOrderAmount;
};

export const removeWhiteSpaces = (e) => {
  if (e.key === " ") {
    e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "").trim();
  }
};

export const removeWhiteSpacesInPassword = (e, formik, input) => {
  let value = e.currentTarget.value;
  if (value.includes(" ")) {
    value = value.replace(/\s/g, "");
  }
  return formik.setFieldValue(input, value);
}

export const modifyPrice = (price) => {
  return price && <span>{renderPrice(price)}</span>;
};

export const modifyDate = (orderDate) => {
  const year = new Date(orderDate).getFullYear();
  return year !== 1 ? moment(orderDate).format("DD-MM-YYYY HH:mm") : "";
};

export const modifyDateFormat = (dateValue) => {
  const year = new Date(dateValue).getFullYear();
  return year !== 1 ? moment(dateValue).format("DD/MM/YYYY") : "";
};

export const trimFormData = (formData) => {
  const trimmedFormData = {};
  Object.keys(formData).forEach((key) => {
    trimmedFormData[key] = formData[key].trim();
  });
  return trimmedFormData;
};

export const handleFirstNameChange = (event, formik) => {
  let cleaned = event.target.value
    .substring(0, globalConfig.allowNameLength)
    .trim();
  formik.setFieldValue("firstName", cleaned);
};

export const handleLastNameChange = (event, formik) => {
  let cleaned = event.target.value
    .substring(0, globalConfig.allowNameLength)
    .trim();
  formik.setFieldValue("lastName", cleaned);
};

export const handlePhoneChange = (event, formik) => {
  // Remove all non-numeric and non-plus characters from the input string
  let cleaned = event.target.value
    .replace(/[^0-9+]/g, "")
    .substring(0, globalConfig.allowPhoneNumberLength);

  // Check if the cleaned string starts with a plus sign
  if (cleaned.startsWith("+")) {
    // If there is another plus sign after the first character, remove it
    const secondPlusIndex = cleaned.indexOf("+", 1);
    if (secondPlusIndex !== -1) {
      cleaned =
        cleaned.slice(0, secondPlusIndex) + cleaned.slice(secondPlusIndex + 1);
    }
  } else {
    // If the cleaned string doesn't start with a plus sign, remove all plus signs
    cleaned = cleaned.replace(/\+/g, "");
  }

  // Update the value of the 'phone' field in the form with the cleaned phone number
  formik.setFieldValue("phone", cleaned);
};

export const renderPrice = (val) => globalConfig.defaultCurrency + setNumberPrecision(val);
export const renderDataAmount = (val) => val && (val / 1000) + "GB";
export const renderSpeed = (val) => val && val.join(" - ");

export const renderData = (dataInMB) =>
  dataInMB && dataInMB < 0
    ? "0 MB"
    : dataInMB >= 900
    ? (dataInMB / 1000).toFixed(2) + " GB"
    : dataInMB > 0
    ? dataInMB.toFixed(2)
    : dataInMB + " MB";

export const formatBundleExpiration = (startDate, endDate, labels) => {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  // Calculate the difference in days
  const timeDifference = endDateTime.getTime() - startDateTime.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  // Calculate the remaining days until the end time
  const currentDate = new Date();
  const rDays = Math.ceil((endDateTime.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
  const remainingDays = rDays > 0 ? rDays : 0;

  // Format the output
  const output = daysDifference + " " + (daysDifference > 1 ? labels.LBL0018 : labels.LBL0108) + " " + labels.LBL0168;

  const usedDataPercentage = ((daysDifference - remainingDays) / daysDifference) * 100;

  return { usedDataPercentage, remainingDays, output };
};

const formatDecimals = (num) => {
  const calcDec = Math.pow(10, 1);
  return Math.trunc(num * calcDec) / calcDec;
}
const checkFloatingValue = (formattedData) => {
  return Number.isInteger(formattedData) ? formattedData : formatDecimals(formattedData)
}

export const checkDataSize = (dataSizeInBytes) => {
  const bytesInOneMB = 1000 * 1000; // 1 MB = 1024 KB, 1 KB = 1024 bytes
  const bytesInOneGB = bytesInOneMB * 1000; // 1 GB = 1024 MB
  
  if(dataSizeInBytes === -1){
    return 'Unlimited';
  }else if (dataSizeInBytes >= bytesInOneGB) {
    // Data is in GB
    const dataSizeInGB = dataSizeInBytes / bytesInOneGB;
    return checkFloatingValue(dataSizeInGB) + " GB";
  } else if (dataSizeInBytes >= bytesInOneMB) {
    // Data is in MB
    const dataSizeInMB = dataSizeInBytes / bytesInOneMB;
    return checkFloatingValue(dataSizeInMB) + " MB";
  } else if(dataSizeInBytes >= 1000) {
    // Data is in bytes
    const dataSizeInMB = dataSizeInBytes / 1000;
    return checkFloatingValue(dataSizeInMB) + " KB";
  } else {
    return 0 + " KB"
  }
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });  
}

export const displayDefaultPhone = (isAuth, userData, getDefaultCallingCode) => {
  let displayPhone = "";
  if (isAuth) {
      if (userData.phone.callingCode) {
          displayPhone += userData.phone.callingCode;
      } else {
          displayPhone += getDefaultCallingCode;
      }
      if (userData.phone.localPhoneNumber) {
          displayPhone += (userData.phone.localPhoneNumber).replace(/^0+/, '') || '0';
      }
  } else {
    displayPhone += getDefaultCallingCode;
  }
  return displayPhone;
}

export const getDayLabel = (days,labels)=> {
  return days > 1 ? labels.LBL0018 : labels.LBL0108
}

export const setDataAmount = (rowData, labels) => {
  return rowData?.dataAmount === -1 ? labels.LBL0167 : rowData?.dataAmountForDisplay;
};

export const getBundleDescription = (bundle) => {
  const countryName = bundle?.country ? bundle?.country.split("(")[0] : '';
  const description = `${countryName} - ${checkDataSize(bundle.totalData)}`;
  return description;
}
export const geteSIMsDescription = (esim) => {
  const countryName = esim?.countries?.length ? esim?.countries[0]?.country?.name?.split("(")[0] : '';
  const description = `${countryName} - ${esim.dataAmountForDisplay}`;
  return description;
}


export const renderDurationIfPlural = (data, labels) => {
  return data ? (data + " " + (data > 1 ? labels.LBL0018 : labels.LBL0108)) : "-"
}